import {Admin, Resource} from 'react-admin';
import DataProvider from './dataProvider';
import AuthProvider from './authProvider';
import {FunctionComponent} from 'react';
import ContentPageListView from './entities/contentPage/ListView';
import ErrorCodeListView from './entities/errorCode/ListView';
import ContentPageShowView from './entities/contentPage/ShowView';
import ErrorCodeShowView from './entities/errorCode/ShowView';
import ContentPageEditView from './entities/contentPage/EditView';
import ErrorCodeEditView from './entities/errorCode/EditView';
import ErrorCodeCreateView from './entities/errorCode/CreateView';
import ContentPageCreateView from './entities/contentPage/CreateView';
import UserListView from './entities/user/ListView';
import UserEditView from './entities/user/EditView';
import UserCreateView from './entities/user/CreateView';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import dutchMessages from 'ra-language-dutch';
import domainMessages from './i18n';
import DocumentCreateView from './entities/document/CreateView';
import DocumentShowView from './entities/document/ShowView';
import DocumentEditView from './entities/document/EditView';
import DocumentListView from './entities/document/ListView';

const messages: { [key: string]: any } = {
    nl: {...dutchMessages, ...domainMessages.nl}
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'nl');

const App: FunctionComponent = () => (
    <Admin
        authProvider={AuthProvider}
        dataProvider={DataProvider}
        i18nProvider={i18nProvider}
    >
        <Resource
            name={'contentPage'}
            list={ContentPageListView}
            show={ContentPageShowView}
            edit={ContentPageEditView}
            create={ContentPageCreateView}
        />
        <Resource
            name={'errorCode'}
            list={ErrorCodeListView}
            show={ErrorCodeShowView}
            edit={ErrorCodeEditView}
            create={ErrorCodeCreateView}
        />
        <Resource
            name={'document'}
            list={DocumentListView}
            show={DocumentShowView}
            edit={DocumentEditView}
            create={DocumentCreateView}
        />
        <Resource
            name={'user'}
            list={UserListView}
            edit={UserEditView}
            create={UserCreateView}
        />
    </Admin>
);

export default App;
