import {FunctionComponent} from 'react';
import Props from './Props';

const ErrorCodeTitle: FunctionComponent<Props> = ({record}) => {
    return (
        <span>{record ? record.type + ': ' + record.code + ' - ' + record.name : 'Errorcode'}</span>
    );
};

export default ErrorCodeTitle;
