import {FunctionComponent} from 'react';
import {Edit, SimpleForm, TextInput} from 'react-admin';
import WYSIWYG from '../../components/WYSIWYG';
import ContentPageTitle from '../../components/ContentPageTitle';

const ContentPageEditView: FunctionComponent = (props) => {
    return (
        <Edit {...props} title={<ContentPageTitle/>}>
            <SimpleForm>
                <TextInput source={'title'}/>
                <WYSIWYG source={'content'} label={'resources.contentPage.fields.content'}/>
            </SimpleForm>
        </Edit>
    );
};

export default ContentPageEditView;
