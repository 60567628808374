import {fetchUtils} from 'ra-core';
import {accessTokenKey} from './authProvider';

export const httpClient = (url: string, options?: fetchUtils.Options): Promise<{ status: number; headers: Headers; body: string; json: any }> => {
    let responseOptions;
    if (options) {
        responseOptions = options;
    } else {
        responseOptions = {};
    }

    if (!responseOptions.headers) {
        responseOptions.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem(accessTokenKey);
    const headers = responseOptions.headers as Headers;
    headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, responseOptions);
};
