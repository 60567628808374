const domainMessages = {
    nl: {
        resources: {
            errorCode: {
                name: 'Errorcode |||| Errorcodes',
                fields: {
                    type: 'Type',
                    code: 'Code',
                    name: 'Naam',
                    led1: 'LED 1',
                    led2: 'LED 2',
                    problemType: 'Probleem type',
                    possibleCause: 'Mogelijke oorzaak',
                    description: 'Beschrijving'
                }
            },
            contentPage: {
                name: 'Content pagina |||| Content pagina\'s',
                fields: {
                    title: 'Titel',
                    content: 'Content'
                }
            },
            user: {
                name: 'Gebruiker |||| Gebruikers',
                fields: {
                    username: 'Gebruikersnaam',
                    password: 'Wachtwoord'
                }
            },
            document: {
                name: 'Document |||| Documenten',
                fields: {
                    type: 'Type',
                    category: 'Categorie',
                    name: 'Naam',
                    description: 'Beschrijving',
                    currentFile: 'Huidig bestand',
                    newFile: 'Nieuw bestand',
                    filename: 'Bestand',
                    subtype: 'Sub-type'
                }
            }
        }
    }
};

export default domainMessages;
