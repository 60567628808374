import {FunctionComponent} from 'react';
import Props from './Props';

const ContentPageTitle: FunctionComponent<Props> = ({record}) => {
    return (
        <span>{record ? 'Contentpage' + ': ' + record.title : 'Contentpage'}</span>
    );
};

export default ContentPageTitle;
