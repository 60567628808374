import {FunctionComponent} from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';
import WYSIWYG from '../../components/WYSIWYG';

const ContentPageCreateView: FunctionComponent = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={'title'}/>
                <WYSIWYG source={'content'} label={'resources.contentPage.fields.content'}/>
            </SimpleForm>
        </Create>
    );
};

export default ContentPageCreateView;
