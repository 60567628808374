import {FunctionComponent} from 'react';
import {RichTextField, Show, SimpleShowLayout, TextField} from 'react-admin';
import ErrorCodeTitle from '../../components/ErrorCodeTitle';

const ErrorCodeShowView: FunctionComponent = (props) => {
    return (
        <Show {...props} title={<ErrorCodeTitle/>}>
            <SimpleShowLayout>
                <TextField source="type" />
                <TextField source="code" />
                <TextField source="led1" />
                <TextField source="led2" />
                <TextField source="name" />
                <TextField source="problemType" />
                <RichTextField source="possibleCause" />
                <RichTextField source="description" />
            </SimpleShowLayout>
        </Show>
    );
};

export default ErrorCodeShowView;
