import restProvider from 'ra-data-simple-rest';
import {httpClient} from './httpClient';

const dataProvider = restProvider(process.env.REACT_APP_API_URL ?? '', httpClient);

const customDataProvider = {
    ...dataProvider,
    create: async (resource: string, params: any): Promise<any> => {
        if (resource !== 'document') {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }

        const base64File = await convertFileToBase64(params.data.file);

        return dataProvider.create(resource, {
            ...params,
            data: {
                ...params.data,
                file: {
                    name: params.data.file.title,
                    content: base64File
                }
            }
        });
    },
    update: async (resource: string, params: any): Promise<any> => {
        if (resource !== 'document') {
            // fallback to the default implementation
            return dataProvider.update(resource, params);
        }

        const base64File = await convertFileToBase64(params.data.file);

        return dataProvider.update(resource, {
            ...params,
            data: {
                ...params.data,
                file: {
                    name: params.data.file.title,
                    content: base64File
                }
            }
        });
    }
};

const convertFileToBase64 = (file: { rawFile: File }) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export default customDataProvider;
