import {FunctionComponent} from 'react';
import {Show, SimpleShowLayout, TextField} from 'react-admin';
import DocumentTitle from '../../components/DocumentTitle';

const DocumentShowView: FunctionComponent = (props) => {
    return (
        <Show {...props} title={<DocumentTitle/>}>
            <SimpleShowLayout>
                <TextField source="type"/>
                <TextField source="category"/>
                <TextField source="name"/>
                <TextField source="description"/>
                <TextField source="filename"/>
                <TextField source="subtype"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default DocumentShowView;
