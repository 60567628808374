import {FunctionComponent} from 'react';
import {Edit, FileField, FileInput, SimpleForm, TextField, TextInput} from 'react-admin';
import DocumentTitle from '../../components/DocumentTitle';

const DocumentEditView: FunctionComponent = (props) => {
    return (
        <Edit {...props} title={<DocumentTitle/>}>
            <SimpleForm>
                <TextInput source="type"/>
                <TextInput source="category"/>
                <TextInput source="name"/>
                <TextInput source="description"/>
                <TextField source={'filename'} label={'resources.document.fields.currentFile'}/>
                <FileInput
                    source="file"
                    label={'resources.document.fields.filename'}
                    accept={'application/pdf'}
                    multiple={false}
                >
                    <FileField source={'src'} title={'title'}/>
                </FileInput>
                <TextInput source="subtype"/>
            </SimpleForm>
        </Edit>
    );
};

export default DocumentEditView;
