import {FunctionComponent} from 'react';
import {Create, FileField, FileInput, SimpleForm, TextInput} from 'react-admin';

const DocumentCreateView: FunctionComponent = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="type"/>
                <TextInput source="category"/>
                <TextInput source="name"/>
                <TextInput source="description"/>
                <FileInput
                    source="file"
                    label={'resources.document.fields.filename'}
                    accept={'application/pdf'}
                    multiple={false}
                >
                    <FileField source={'src'} title={'title'}/>
                </FileInput>
                <TextInput source="subtype"/>
            </SimpleForm>
        </Create>
    );
};

export default DocumentCreateView;
