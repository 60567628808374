import {FunctionComponent} from 'react';
import {BooleanField, Datagrid, Filter, List, ListProps, NullableBooleanInput, TextField, TextInput} from 'react-admin';

const DocumentFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <TextInput source={'name'} alwaysOn/>
            <TextInput source={'type'}/>
            <TextInput source={'category'}/>
            <TextInput source={'description'}/>
            <TextInput source={'subtype'}/>
            <NullableBooleanInput
                alwaysOn
                source={'fetched'}
            />
            <NullableBooleanInput
                alwaysOn
                source={'error'}
            />
        </Filter>
    );
};

const DocumentListView: FunctionComponent<ListProps> = (props) => {
    return (
        <List {...props} filters={<DocumentFilter/>} perPage={25}>
            <Datagrid rowClick="edit">
                <TextField source="name"/>
                <TextField source="description"/>
                <TextField source="category"/>
                <TextField source="type"/>
                <TextField source="subtype"/>
                <TextField source="filename"/>
                <BooleanField source={'fetched'}/>
                <BooleanField source={'error'}/>
            </Datagrid>
        </List>
    );
};

export default DocumentListView;
