import {FunctionComponent} from 'react';
import {Datagrid, Filter, List, ListProps, TextField, TextInput} from 'react-admin';

const UserFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <TextInput source={'username'} alwaysOn/>
        </Filter>
    );
};

const UserListView: FunctionComponent<ListProps> = (props) => {
    return (
        <List {...props} filters={<UserFilter/>}>
            <Datagrid rowClick="edit">
                <TextField source={'username'}/>
            </Datagrid>
        </List>
    );
};

export default UserListView;
