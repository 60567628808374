import {FunctionComponent} from 'react';
import {Create, PasswordInput, SimpleForm, TextInput} from 'react-admin';

const UserCreateView: FunctionComponent = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source={'username'}/>
                <PasswordInput source={'password'}/>
            </SimpleForm>
        </Create>
    );
};

export default UserCreateView;
