import {FunctionComponent} from 'react';
import {Edit, PasswordInput, SimpleForm, TextField} from 'react-admin';

const UserEditView: FunctionComponent = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextField source={'username'}/>
                <PasswordInput source={'password'}/>
            </SimpleForm>
        </Edit>
    );
};

export default UserEditView;
