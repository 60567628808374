export enum LedOption {
    OFF = 'Uit',
    ON = 'Aan',
    CONSTANT_BLINK = 'Constant knip',
    ONE = '1',
    TWO = '2',
    THREE = '3',
    FOUR = '4',
    FIVE = '5',
    SIX = '6',
    SEVEN = '7',
}

export enum ErrorCodeType {
    E_CODE = 'E code',
    OPE_CODE = 'OPE code',
    PROTECTION_CODE = 'Protectiecode'
}

export interface ErrorCodeDTO {
    id: string;
    type: ErrorCodeType;
    led1: LedOption;
    led2: LedOption;
    code: string;
    name: string;
    problemType: string;
    possibleCause: string;
    description: string;
}

export interface ContentPageDTO {
    id: string;
    title: string;
    content: string;
}

export interface DocumentDTO {
    id: string;
    type: string;
    category: string;
    name: string;
    description: string;
    filename: string;
    subtype: string;
}
