const API_URL = process.env.REACT_APP_API_URL;
export const accessTokenKey = 'ACCESS_TOKEN';
export const userIdKey = 'USER_ID';

export default {
    login: async ({username, password}: { username: string, password: string }): Promise<void> => {
        const request = new Request(API_URL + '/app/login', {
            method: 'POST',
            body: JSON.stringify({
                username,
                password
            }),
            headers: new Headers({'Content-Type': 'application/json'})
        });
        try {
            const response = await fetch(request);

            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }

            const {userId, token} = await response.json();

            await localStorage.setItem(userIdKey, userId);
            await localStorage.setItem(accessTokenKey, token);

            return Promise.resolve();
        } catch (e) {
            return Promise.reject();
        }
    },
    logout: (): Promise<void> => {
        localStorage.removeItem(userIdKey);
        localStorage.removeItem(accessTokenKey);
        return Promise.resolve();
    },
    checkError: ({status}: { status: number }): Promise<void> => {
        if (status === 401 || status === 403) {
            localStorage.removeItem(userIdKey);
            localStorage.removeItem(accessTokenKey);
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: (): Promise<void> => {
        return (localStorage.getItem(userIdKey) && localStorage.getItem(accessTokenKey))
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: (): Promise<void> => Promise.resolve()
};
