import {FunctionComponent} from 'react';
import {RichTextField, Show, SimpleShowLayout, TextField} from 'react-admin';
import ContentPageTitle from '../../components/ContentPageTitle';

const ContentPageShowView: FunctionComponent = (props) => {
    return (
        <Show {...props} title={<ContentPageTitle/>}>
            <SimpleShowLayout>
                <TextField source="title"/>
                <RichTextField source="content"/>
            </SimpleShowLayout>
        </Show>
    );
};

export default ContentPageShowView;
