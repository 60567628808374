import {FunctionComponent} from 'react';
import {Datagrid, List, ListProps, TextField} from 'react-admin';

const ContentPageListView: FunctionComponent<ListProps> = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
        </Datagrid>
    </List>
);

export default ContentPageListView;
