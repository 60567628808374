import {FunctionComponent} from 'react';
import {Create, SelectInput, SimpleForm, TextInput} from 'react-admin';
import {ErrorCodeType, LedOption} from '../../types/coolmark-backend';
import WYSIWYG from '../../components/WYSIWYG';

const ErrorCodeCreateView: FunctionComponent = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <SelectInput
                    source={'type'}
                    choices={Object.values(ErrorCodeType).map((type) => ({
                        id: type,
                        name: type
                    }))}
                    defaultValue={Object.values(ErrorCodeType)[0]}
                />
                <TextInput source="code"/>
                <SelectInput
                    source={'led1'}
                    choices={Object.values(LedOption).map((type) => ({
                        id: type,
                        name: type
                    }))}
                    defaultValue={Object.values(LedOption)[0]}
                />
                <SelectInput
                    source={'led2'}
                    choices={Object.values(LedOption).map((type) => ({
                        id: type,
                        name: type
                    }))}
                    defaultValue={Object.values(LedOption)[0]}
                />
                <TextInput source={'name'}/>
                <TextInput source={'problemType'}/>
                <WYSIWYG source={'possibleCause'} label={'resources.errorCode.fields.possibleCause'}/>
                <WYSIWYG source={'description'} label={'resources.errorCode.fields.description'}/>
            </SimpleForm>
        </Create>
    );
};

export default ErrorCodeCreateView;
