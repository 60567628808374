import {FunctionComponent} from 'react';
import Props from './Props';

const DocumentTitle: FunctionComponent<Props> = ({record}) => {
    return (
        <span>{record ? record.name : 'Document'}</span>
    );
};

export default DocumentTitle;
