import {FunctionComponent} from 'react';
import Props from './Props';
import RichTextInput from 'ra-input-rich-text';

const WYSIWYG: FunctionComponent<Props> = ({source, label}) => {
    return (
        <RichTextInput
            source={source}
            label={label}
            toolbar={[
                [{'header': [3, false]}],
                ['bold'],
                [{'list': 'ordered'}],
                ['link', 'image']
            ]}
        />
    );
};

export default WYSIWYG;
