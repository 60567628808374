import {FunctionComponent} from 'react';
import {Datagrid, Filter, List, ListProps, SelectInput, TextField} from 'react-admin';
import {ErrorCodeType} from '../../types/coolmark-backend';

const ErrorCodeFilter: FunctionComponent<any> = (props) => {
    return (
        <Filter {...props}>
            <SelectInput
                alwaysOn
                source={'type'}
                choices={Object.values(ErrorCodeType).map((type) => ({
                    id: type,
                    name: type
                }))}
            />
        </Filter>
    );
};

const ErrorCodeListView: FunctionComponent<ListProps> = (props) => {
    return (
        <List {...props} filters={<ErrorCodeFilter/>} perPage={25}>
            <Datagrid rowClick="edit">
                <TextField source="type"/>
                <TextField source="code"/>
                <TextField source="name"/>
                <TextField source="led1"/>
                <TextField source="led2"/>
            </Datagrid>
        </List>
    );
};

export default ErrorCodeListView;
